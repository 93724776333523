import React, { FC } from "react";
import { Modal } from "react-bootstrap";

/**
 * Interface created for the reusable custom modal to manipulate its props
 */
interface IProps {
  show: boolean;
  onClose: () => void;
  heading: string;
  hasCloseButton?: boolean;
  children: React.ReactNode;
  className?: string;
}

/**
 * @returns a custom reusable modal popup to use throughout
 * the project wherever is a popup needed
 */

const ReactModal: FC<IProps> = ({ show, onClose, heading, hasCloseButton =false, children, className = "custom-dailog" }) => {
  /** a method created to handle the closing functionality of the modal */
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className={`custom-modal`} dialogClassName={className}>
      <Modal.Body>
        {/* an optional close button */}
        {hasCloseButton && (
          <button onClick={handleClose} className="close-button">
            <span>&times;</span>
          </button>
        )}
        {/* Title text of the modal */}
        <div className="text-center">
          <h5>{heading}</h5>
        </div>

        {children}
      </Modal.Body>
    </Modal>
  );
};

export default ReactModal;
