export const globalConstants = {
  password_min_length: 6,
  password_max_length: 32,

  email_regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/,
  name_regex: /^([a-zA-Z]+(?:\s[a-zA-Z]+)*)$/,
};

export const UserRoles = {
  Admin: "admin",
  User: "user",
};

export const errorMessages = {
  required_field: "This Field is required.",
  invalid_creds: "Invalid Email or Password",
  invalid_email: "Invalid Email",
  invalid_password: "Invalid Password",
  invalid_name: "Invalid Name",
  confirm_pass_not_matching: "Password & Confirm password do not match",
  project_name_required:"Project Name is required.",
  item_name_required:"Item Name is required."
};

export const fileProps = {
  file_size: 10,
  accepted_formats: ["jpeg", "png"],
};
