import React, { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { usePurchaseItems, useRemoveItemFromCart } from "@/services/apiMethod";
import { useStateContext } from "@/context/state";
import { svgIcons } from "@/styles/svgIcon/svgIcon";
import { PageLoader } from "@/components/common/Loader";
import ConfirmationModal from "../common/ConfirmationModal";
import { useRouter } from "next/router";


interface cartItem {
  template: {
    title: string;
    type: string;
    image?: string;
  };
  title: string;
  id: string;
  image?: string;
}

function CartModal() {
  const { data: session } = useSession();
  const {
    tokensData,
    isCartOpen,
    setIsCartOpen,
    cartData,
    isCartLoding,
    refetchCart,
  } = useStateContext();
  const router = useRouter();


  const [cartItems, setCartItems] = React.useState<cartItem[]>([]);
  const apiHeaders = {
    Authorization: `Bearer ${session?.user.access}`,
  };
  const { removeItemFromCart, isRemoveItemLoading } = useRemoveItemFromCart();

  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const { purchaseItems, isPurchaseItemLoading } = usePurchaseItems();

  useEffect(() => {
    if (isCartOpen) {
      if (cartData && cartData.results) {
        setCartItems(cartData.results);
      } else {
        setCartItems([]);
      }
    }
  }, [isCartOpen, cartData, refetchCart]);

  const handlePurchaseModal = () => {
    if (cartItems && cartItems.length > 0) {
      setShowPurchaseModal(!showPurchaseModal);
    }
  };

  const renderPurchaseModal = () => {
    return (
      <ConfirmationModal
        heading="Purchase"
        onAccept={handlePurchase}
        show={showPurchaseModal}
        onClose={() => {
          setShowPurchaseModal(false);
        }}
      />
    );
  };

  /** to handle the purchase of the item added to cart */
  const handlePurchase = () => {
    const productIds: string[] = [];
    cartItems.map((item) => productIds.push(item.id));

    purchaseItems([productIds, apiHeaders])
      .then(() => {
        setIsCartOpen(false);
        setCartItems([]);
        refetchCart();
        if (router.route == `/projects/editor/[id]`) {
          router.push("/projects");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      className={`cart-aside ${
        tokensData && tokensData.item_processed_monthly_remain === 0 ? "noItemWrap" : ""
      }`}
    >
      {isCartLoding || isPurchaseItemLoading || isRemoveItemLoading ? (
        <PageLoader />
      ) : (
        <div className="div-wrapper">
          {renderPurchaseModal()}
          <div className="overlap-group-4">
            <div className="frame-2" />
            <div className="frame-wrapper">
              <div className="frame-3">
                <div className="your-cart">YOUR CART</div>
                <span
                  onClick={() => setIsCartOpen(false)}
                  className="cancel-instance"
                >
                  {svgIcons.cartCancelIcon}
                </span>
              </div>
            </div>
            <div className="text-wrapper-2">
              {cartData?.count} {cartData?.count == 1 ? "Item" : "Items"}
            </div>
            <div className="frame-4">
              <div className="text-wrapper-3">
                Remaining downloads {tokensData?.item_processed_monthly_remain}
              </div>
            </div>
            {tokensData?.item_processed_monthly_remain === 0 && (
              <div className="msgNoProduct">
                You have exceeded your subscription download limit. Additional downloads are now
                charged at £100 per download.
              </div>
            )}
            <div className="frame-5">
              {cartItems && cartItems.length > 0 ? (
                cartItems.map((cartItem: cartItem, index) => (
                  <div className="frame-7" key={index}>
                    <div className="frame-8">
                      <div className="ROBLOX-ITEM-RENDERS-wrapper">
                        {cartItem.image && (
                          <img
                            className="ROBLOX-ITEM-RENDERS"
                            alt="Roblox ITEM RENDERS"
                            src={cartItem.image}
                          />
                        )}
                      </div>
                      <div className="frame-9">
                        <div className="frame-10">
                          <div className="text-wrapper-4">{cartItem.title}</div>
                          <div className="text-wrapper-5">{cartItem.template.title}</div>
                          <div className="text-wrapper-6">{cartItem.template.type}</div>
                          <div className="text-wrapper-6">
                            {tokensData?.item_processed_cost
                              ? `£${tokensData?.item_processed_cost}`
                              : `0`}
                          </div>
                        </div>
                        <div className="frame-11">
                          <button
                            className="remove-btn"
                            onClick={() => {
                              removeItemFromCart([[cartItem.id], apiHeaders]).then(() =>
                                refetchCart()
                              );
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-item-cart">
                  <span>No item added in your cart</span>
                </div>
              )}
            </div>

            <div className="frame-15">
              {tokensData?.item_processed_monthly_remain === 0 && (
                <div className={`frame-16`}>
                  <div className="text-wrapper-4">Standard plan</div>
                  <div className="frame-17">
                    <div className="text-wrapper-8">Downloads remaining:</div>
                    <div className="text-wrapper-9">
                      {tokensData?.item_processed_monthly_remain}
                    </div>
                  </div>
                  <div className="frame-19">
                    <div className="text-wrapper-12">Additional download cost:</div>
                    <div className="text-wrapper-13">
                      {tokensData?.item_processed_cost
                        ? `£${tokensData?.item_processed_cost}`
                        : `£150`}
                    </div>
                  </div>
                </div>
              )}

              <div className="frame-12">
                <div className="text-wrapper-4">Total</div>
                <div className="text-wrapper-7">
                  {tokensData?.item_processed_cost
                    ? `£${tokensData?.item_processed_cost * cartItems.length}`
                    : `0`}
                </div>
              </div>
              <div
                className={`button-tertiary ${
                  cartItems && cartItems.length === 0 ? "disabled" : ""
                }`}
                onClick={() => {
                  cartItems && cartItems.length >= 0 && handlePurchaseModal();
                }}
              >
                <div className="btn-label">Buy and Build Item</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartModal;
