import React from "react";
import ReactModal from "./ReactModal";
interface modelProps {
  heading: string;
  onClose: () => void;
  show: boolean;
  onAccept: () => void;
}

function ConfirmationModal({ heading, onClose, show, onAccept }: modelProps) {
  return (
    <ReactModal
      hasCloseButton={false}
      onClose={onClose}
      heading={`${heading} Item`}
      className="delete-modal-container"
      show={show}
    >
      <div className="custom-modal-text">
        Are you sure you want to {heading.toLowerCase()} the Item?
      </div>

      <div className="button-wrapper">
        <button
          className="cus-btn ghost-button"
          onClick={onAccept}
        >
          Yes
        </button>
      </div>
      <div className="modal-cancel-btn" onClick={onClose}>
        <span>Cancel</span>
      </div>
    </ReactModal>
  );
}

export default ConfirmationModal;
