import useSWR, { mutate as clearCache } from "swr";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";
import {
  ITemplatesListData,
  IProjectsListData,
  IProjectDetailsData,
  IItemsListData,
} from "@/types";

/** Method to handle post request in swr */
async function postFetcher(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;

  return fetch(`${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
  }).then((res) => res.json());
}

/** Method to handle post data in item actions */
async function postDataFetcher(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;

  const requestBody = {
    id: data,
  };

  return fetch(`${url}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers,
  }).then((res) => res.json());
}

export const useProjectsData = (
  offset = 0,
  limit = Number(process.env.NEXT_PUBLIC_API_PAGE_LIMIT),
  filterString = ""
) => {
  const { data, error, isLoading, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}projectSimple?limit=${limit}&offset=${offset}&${filterString}`
  );
  return {
    projectsData: data as IProjectsListData,
    isLoading,
    isError: error,
    refetch: mutate,
  };
};

export const useItemsData = (
  projectId: string | string[] | undefined,
  offset = 0,
  limit = Number(process.env.NEXT_PUBLIC_API_PAGE_LIMIT),
  filterString = ""
) => {
  const { data, error, isLoading, mutate } = useSWR(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }itemSimple?limit=${limit}&offset=${offset}${
      projectId ? "&project=" + projectId : ""
    }}&${filterString}`,
    { refreshInterval: 1000 }
  );
  return {
    items: data as IItemsListData,
    isLoading,
    isError: error,
    refetch: mutate,
  };
};

export function useProjectData(
  slug: string | string[] | undefined,
  filterString: string
) {
  const { data, error, isLoading, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}projectSimple/${slug}?${filterString}`
  );
  return {
    project: data as IProjectDetailsData,
    isLoading,
    isError: error,
    refetch: mutate,
  };
}

// Creates a new Item - note the data format
export const createItem = async (
  title: string,
  project: string | string[] | undefined,
  template: string,
  headers: any
) => {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}item/`, {
    method: "POST",

    body: JSON.stringify({
      title: title,
      project: {
        id: project,
      },
      template: {
        id: template,
      },
    }),
    headers: {
      ...headers,
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => {
    return res.json();
  });
};

export function useItemData(id: string | string[] | null) {
  const { data, isLoading, error, mutate } = useSWRImmutable(
    id ? `${process.env.NEXT_PUBLIC_API_BASE_URL}item/${id}/` : null
  );

  return {
    item: data,
    isLoading: isLoading,
    isError: error,
    refetch: mutate,
  };
}

export function useItemPreviewData(id: string | string[] | null) {
  const { data, isLoading, error, mutate } = useSWRImmutable(
    id ? `${process.env.NEXT_PUBLIC_API_BASE_URL}itemPreview/${id}/` : null
  );

  return {
    item: data,
    isLoading: isLoading,
    isError: error,
    refetch: mutate,
  };
}

/** Method to create the project */
async function createProject(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return fetch(`${url}`, {
    method: "POST",
    body: formData,
    headers,
  }).then((res) => res.json());
}

export const useCreateProject = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}project/`,
    createProject
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    createProject: trigger,
    reset,
  };
};

async function updateProject(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return fetch(`${url}`, {
    method: "PATCH",
    body: formData,
    headers,
  }).then((res) => res.json());
}

export const useUpdateProject = (projectId: string | string[] | undefined) => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}projectSimple/${projectId}/`,
    updateProject
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    updateProject: trigger,
    reset,
  };
};



async function renameItem(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return fetch(`${url}`, {
    method: "PATCH",
    body: formData,
    headers,
  }).then((res) => res.json());
}

export const useRenameItem = (itemId: string | string[] | undefined) => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}itemSimple/${itemId}/`,
    renameItem
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    renameItem: trigger,
    reset,
  };
};

/** Method to get the list of tokens & members */

export const useTokensData = (isLoggedIn: Boolean) => {
  const { data, isLoading, error, mutate } = useSWR(
    isLoggedIn ? `${process.env.NEXT_PUBLIC_API_BASE_URL}my/org/` : false
  );
  return {
    tokensData: data,
    isLoading,
    isError: error,
    refetch: mutate,
  };
};
/** show  the list of members */
export const useMembersData = (
  offset = 0,
  limit = Number(process.env.NEXT_PUBLIC_API_PAGE_LIMIT),
  filterString = ""
) => {
  const { data, isLoading, error, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}team?limit=${limit}&offset=${offset}&${filterString}`
  );

  return {
    membersData: data,
    isListLoading: isLoading,
    isError: error,
    refetchMembers: mutate,
  };
};

/** methods to get the list of templates */
export const useTemplatesData = (
  offset = 0,
  limit = Number(process.env.NEXT_PUBLIC_API_PAGE_LIMIT),
  filterString = ""
) => {
  let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}template/?limit=${limit}&offset=${offset}`;
  if (filterString) {
    url = `${process.env.NEXT_PUBLIC_API_BASE_URL}template/?limit=${limit}&offset=${offset}&${filterString}`;
  }
  clearCache(url);
  const { data, isLoading, error, mutate } = useSWR(url);

  return {
    templatesData: data as ITemplatesListData,
    isTemplateLoading: isLoading,
    isTemplatesError: error,
    refetch: mutate
  };
};

/** Method to update the item for preview */
async function updateItem(url: string, { arg }: { arg: any }) {
  const [data, headers] = arg;
  return fetch(`${url}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      ...headers,
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => res.json());
}

export const useUpdateItem = (id: any) => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}item/${id}/`,
    updateItem
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    updateItem: trigger,
    reset,
  };
};

/** Show the data available in the cart */
export const useShowCartData = (isLoggedIn: Boolean) => {
  const { data, isLoading, error, mutate } = useSWR(
    isLoggedIn ? `${process.env.NEXT_PUBLIC_API_BASE_URL}cart/` : false
  );
  return {
    cartData: data,
    isCartLoding: isLoading,
    isCartError: error,
    refetchCart: mutate,
  };
};

export const useAddToCart = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/cart/items/add/`,
    postDataFetcher
  );
  return {
    response: data,
    cartLoading: isMutating,
    isAddItemError: error,
    addToCart: trigger,
    reset,
  };
};

export const useRemoveItemFromCart = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/cart/items/remove/`,
    postDataFetcher
  );
  return {
    response: data,
    isRemoveItemLoading: isMutating,
    isRemoveItemError: error,
    removeItemFromCart: trigger,
    reset,
  };
};

export const useDeleteProject = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/delete/projects/`,
    postDataFetcher
  );
  return {
    response: data,
    isDeleteLoading: isMutating,
    isDeleteError: error,
    deleteProject: trigger,
    reset,
  };
};

export const useCopyProject = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/copy/projects/`,
    postDataFetcher
  );
  return {
    response: data,
    isCopyLoading: isMutating,
    isCopyError: error,
    copyProject: trigger,
    reset,
  };
};

export const useDeleteItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/delete/items/`,
    postDataFetcher
  );
  return {
    response: data,
    isDeleteItemLoading: isMutating,
    isDeleteItemError: error,
    deleteItems: trigger,
    reset,
  };
};

export const useDownloadItemsImages = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/download/items/image/`,
    postDataFetcher
  );
  return {
    response: data,
    isDownloadItemImageLoading: isMutating,
    isDownloadItemImageError: error,
    downloadItemsImages: trigger,
    reset,
  };
};

export const useCopyItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/copy/items/`,
    postDataFetcher
  );
  return {
    response: data,
    isCopyItemLoading: isMutating,
    isCopyItemError: error,
    copyItems: trigger,
    reset,
  };
};

export const useCopyItemsToProject = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/copy/items/to/project/`,
    postFetcher
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    copyItemsToProject: trigger,
    reset,
  };
};

export const usePurchaseItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/cart/items/checkout/`,
    postDataFetcher
  );
  return {
    response: data,
    isPurchaseItemLoading: isMutating,
    isPurchaseItemError: error,
    purchaseItems: trigger,
    reset,
  };
};

export const useReviewItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/review/items/`,
    postDataFetcher
  );
  return {
    response: data,
    isReviewItemLoading: isMutating,
    isReviewItemError: error,
    sendItemsToReview: trigger,
    reset,
  };
};

/** method for admin to approve the request of the users  */
export const useApproveItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/approve/items/`,
    postDataFetcher
  );
  return {
    response: data,
    isApprovalLoading: isMutating,
    isApprovalError: error,
    approveItems: trigger,
    reset,
  };
};

export const useRejectItems = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/reject/items/`,
    postDataFetcher
  );
  return {
    response: data,
    isRejectItemLoading: isMutating,
    isRejectItemError: error,
    rejectItems: trigger,
    reset,
  };
};

export const useUpgradeUsers = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/upgrade/users/`,
    postDataFetcher
  );
  return {
    response: data,
    isUpgradeLoading: isMutating,
    isUpgradeError: error,
    upgradeUsers: trigger,
    reset,
  };
};

export const useDowngradeUsers = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/downgrade/users/`,
    postDataFetcher
  );
  return {
    response: data,
    isDowngradeLoading: isMutating,
    isDowngradeError: error,
    downgradeUsers: trigger,
    reset,
  };
};

export const useForgotPassword = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}password_reset/`,
    postFetcher
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    forgotPassword: trigger,
    reset,
  };
};

export const useValidateToken = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}password_reset/validate_token/`,
    postFetcher
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    validateToken: trigger,
    reset,
  };
};

export const useResetPassword = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}password_reset/confirm/`,
    postFetcher
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    resetPassword: trigger,
    reset,
  };
};

export const useAddUsers = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/create/user/`,
    postFetcher
  );
  return {
    response: data,
    isUserLoading: isMutating,
    isUserError: error,
    addUsers: trigger,
    reset,
  };
};

export const useAddRecentColor = () => {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/add/colors/`,
    postFetcher
  );
  return {
    response: data,
    isLoading: isMutating,
    isError: error,
    addRecentColor: trigger,
    reset,
  };
};

/** Show the data available in the cart */
export const useRecentColor = () => {
  const { data, isLoading, error, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}shortcut/list/colors`
  );
  return {
    colors: data,
    isLoading: isLoading,
    isError: error,
    refetch: mutate,
  };
};

/** Get the list of Faqs on Dashboard */
export const useFaqData = () => {
  const { data, isLoading, error, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}faq/`
  );
  return {
    faqData: data,
    isFaqLoading: isLoading,
    isFaqError: error,
    refetchFaq: mutate,
  };
};

/** Get the list of Faqs on Dashboard */
export const useUpdatesData = (
  offset = 0,
  limit = Number(process.env.NEXT_PUBLIC_API_PAGE_LIMIT),
  filterString = ""
) => {
  const { data, isLoading, error, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}update/?limit=${limit}&offset=${offset}&${filterString}`
  );
  return {
    updateData: data,
    isUpdateLoading: isLoading,
    isUpdateError: error,
    refetchUpdates: mutate
  };
};
