import React from "react";
import { motion } from "framer-motion";

function Loader() {
  return (
    <div className="statusFlex loader">
      <h3>Building...</h3>
      <img src={"/loader.svg"} alt="loader" />
    </div>
  );
}

export const PageLoader = () => {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

export const ButtonLoader = () => {
  return <div className="spinner"></div>;
};

export default Loader;
