import { createContext, useContext, useState, useEffect } from "react";

import { useSession } from "next-auth/react";
import {
  useAddToCart,
  useShowCartData,
  useTokensData,
} from "@/services/apiMethod";
import CartModal from "@/components/templates/CartModal";
import { UserRoles } from "@/components/common/globalConstants";
import { useIntercom } from "react-use-intercom";

/** A context created to use states/props throughout the project */
const Context = createContext();
/**
 * @returns a state provider to use in the project to manage the workflow according to the requirements.
 */
export function StateProvider({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState({});

  const [itemName, setItemName] = useState("");
  const [nameError, setNameError] = useState("");
  const [moveTo, setMoveTo] = useState([0, 0]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [editorState, setEditorState] = useState([]);
  const [openPanel, setOpenPanel] = useState(null);
  const [openPanelGroup, setOpenPanelGroup] = useState("");
  const [openPanelEditor, setOpenPanelEditor] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  /** State made to show the template detail on plus minus icon */
  const [selectedTemplateDetail, setSelectedTemplateDetail] = useState(null);
  const [selectedEditorPosition, setSelectedEditorPosition] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [editableProjectData, setEditableProjectData] = useState({});
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [isProjectNameEditable, setIsProjectNameEditable] = useState(false);
  const [projectNameText, setProjectNameText] = useState("");

  const [editableItemData, setEditableItemData] = useState({});
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const { data: session } = useSession();

  const [viewType, setViewType] = useState("list");

  const userType = session?.user?.user?.user_level;
  const {
    tokensData,
    isLoading: isTokenDataLoading,
    refetch: refetchTokens,
  } = useTokensData(session?.user ? true : false);
  const { addToCart, cartLoading } = useAddToCart();
  const { cartData, isCartLoding, refetchCart } = useShowCartData(
    session?.user ? true : false
  );
  const { boot, show } = useIntercom();

  const [thumbnail, setThumbnail] = useState("");

  const ResetStates = () => {
    setSelectedProduct(null);
    setItemName("");
  };

  const handleRouteChange = (url) => {
    ResetStates();
  };

  const handleMouseMove = (event) => {
    // 👇 Get mouse position relative to element
    const localX = event.clientX - event.target.offsetLeft;
    const localY = event.clientY - event.target.offsetTop;

    setSelectedEditorPosition({ x: localX, y: localY });
  };

  useEffect(() => {
    fetchData();
  }, [session]);

  /* Detect Window Width */
// Define the threshold width
  const thresholdWidth = 600; // Adjust this value as needed
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < thresholdWidth);

    useEffect(() => {
      // Function to handle window resize
      const handleResize = () => {
          const newWidth = window.innerWidth;
          setWindowWidth(newWidth);
          if(isSidebarOpen && (newWidth < thresholdWidth)){
            setIsSidebarOpen(false);
          }
      };

      // Add event listener for window resize
      window.addEventListener('resize', handleResize);

      // Clean up the event listener when the component unmounts
      return () => window.removeEventListener('resize', handleResize);
  }, [thresholdWidth]);

  /** Get data from session after login */

  const fetchData = async () => {
    if (session) {
      // Start Intercom once we have user data
      boot({
        name: session.user.name,
        email: session.user.email,
      });
    }
  };

  const data = {
    isOpen,
    setIsOpen,
    status,
    setStatus,
    moveTo,
    setMoveTo,
    itemName,
    setItemName,
    isSidebarOpen,
    setIsSidebarOpen,
    selectedProduct,
    setSelectedProduct,
    nameError,
    setNameError,
    editorState,
    setEditorState,
    openPanel,
    setOpenPanel,
    showCreateProjectModal,
    setShowCreateProjectModal,
    tokensData,
    refetchTokens,
    isTokenDataLoading,
    userType,
    selectedTemplateDetail,
    setSelectedTemplateDetail,
    handleMouseMove,
    selectedEditorPosition,
    isCartOpen,
    setIsCartOpen,
    addToCart,
    cartLoading,
    selectedEditorPosition,
    setSelectedEditorPosition,
    openPanelEditor,
    setOpenPanelEditor,
    bootIntercom: boot,
    showIntercom: show,
    thumbnail,
    setThumbnail,
    cartData,
    isCartLoding,
    refetchCart,
    openPanelGroup,
    setOpenPanelGroup,
    editableProjectData,
    setEditableProjectData,
    showEditProjectModal,
    setShowEditProjectModal,
    editableItemData,
    setEditableItemData,
    showEditItemModal,
    setShowEditItemModal,
    isProjectNameEditable,
    setIsProjectNameEditable,
    projectNameText,
    setProjectNameText,
    viewType,
    setViewType
  };

  return (
    <Context.Provider value={data}>
      {children}
      {isCartOpen && userType === UserRoles.Admin && (
        <CartModal isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      )}
    </Context.Provider>
  );
}

export function useStateContext() {
  const {
    isOpen,
    setIsOpen,
    status,
    setStatus,
    moveTo,
    setMoveTo,
    itemName,
    setItemName,
    isSidebarOpen,
    setIsSidebarOpen,
    selectedProduct,
    setSelectedProduct,
    nameError,
    setNameError,
    editorState,
    setEditorState,
    openPanel,
    setOpenPanel,
    showCreateProjectModal,
    setShowCreateProjectModal,
    tokensData,
    refetchTokens,
    isTokenDataLoading,
    userType,
    selectedTemplateDetail,
    setSelectedTemplateDetail,
    handleMouseMove,
    isCartOpen,
    setIsCartOpen,
    addToCart,
    cartLoading,
    selectedEditorPosition,
    setSelectedEditorPosition,
    openPanelEditor,
    setOpenPanelEditor,
    bootIntercom,
    showIntercom,
    thumbnail,
    setThumbnail,
    cartData,
    isCartLoding,
    refetchCart,
    openPanelGroup,
    setOpenPanelGroup,
    editableProjectData,
    setEditableProjectData,
    showEditProjectModal,
    setShowEditProjectModal,
    editableItemData,
    setEditableItemData,
    showEditItemModal,
    setShowEditItemModal,
    isProjectNameEditable,
    setIsProjectNameEditable,
    projectNameText,
    setProjectNameText,
    viewType,
    setViewType
  } = useContext(Context);
  return {
    isOpen,
    setIsOpen,
    status,
    setStatus,
    moveTo,
    setMoveTo,
    itemName,
    setItemName,
    isSidebarOpen,
    setIsSidebarOpen,
    selectedProduct,
    setSelectedProduct,
    nameError,
    setNameError,
    editorState,
    setEditorState,
    openPanel,
    setOpenPanel,
    showCreateProjectModal,
    setShowCreateProjectModal,
    tokensData,
    refetchTokens,
    isTokenDataLoading,
    userType,
    selectedTemplateDetail,
    setSelectedTemplateDetail,
    handleMouseMove,
    selectedEditorPosition,
    isCartOpen,
    setIsCartOpen,
    addToCart,
    cartLoading,
    selectedEditorPosition,
    setSelectedEditorPosition,
    openPanelEditor,
    setOpenPanelEditor,
    bootIntercom,
    showIntercom,
    thumbnail,
    setThumbnail,
    cartData,
    isCartLoding,
    refetchCart,
    openPanelGroup,
    setOpenPanelGroup,
    editableProjectData,
    setEditableProjectData,
    showEditProjectModal,
    setShowEditProjectModal,
    editableItemData,
    setEditableItemData,
    showEditItemModal,
    setShowEditItemModal,
    isProjectNameEditable,
    setIsProjectNameEditable,
    projectNameText,
    setProjectNameText,
    viewType,
    setViewType
  };
}
