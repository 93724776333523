import "bootstrap/dist/css/bootstrap.min.css";
import "@/styles/index.scss";
import type { AppProps } from "next/app";
import Head from "next/head";

import { AnimatePresence } from "framer-motion";
import { StateProvider } from "../context/state";
import { SWRConfig } from "swr";

import { SessionContext, SessionProvider, getSession, signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { PageLoader } from "@/components/common/Loader";
import { IntercomProvider } from "react-use-intercom";
import PlausibleProvider from 'next-plausible'

// import i18n (needs to be bundled ;)) 
import '../services/i18n';

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const [loading, setLoading] = useState(false);
  const [redirectAndLogout, setRedirectAndLogout] = useState(false);
  const router = useRouter();
  const handleRouteChange = () => {
    setLoading(true);
    return;
  };

  const handleRouteComplete = () => {
    setLoading(false);
    return;
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteComplete);

    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const intercomId: string = "ri554d43";

  useEffect(() => {
    if (redirectAndLogout) {
      setRedirectAndLogout(false);
      signOut({ redirect: true });
    }
  }, [redirectAndLogout]);

  return (
    <PlausibleProvider domain="metamerch.io">
      <IntercomProvider appId={"ri554d43"}>
        <SessionProvider session={session}>
          <SessionContext.Consumer>
            {(context) => {
              let headers = {};
              if (context && context.data) {
                const userData = context.data.user;
                if (userData) {
                  headers = {
                    ...headers,
                    Authorization: `Bearer ${userData.access}`
                  };
                }
              }

              return (
                context?.status !== "loading" && (
                  <SWRConfig
                    value={{
                      fetcher: async (resource, init) => {
                        if (init && init.headers) {
                          headers = {
                            ...init.headers,
                            ...headers
                          };
                        }

                        const res = await fetch(resource, {
                          ...init,
                          headers
                        });
                        const response = await res.json();

                        //console.log(res);
                        if (response && res.status === 401) {
                          console.log("unauthorised");
                          //signOut({ callbackUrl: router.pathname })
                        }

                        if (response && response.code === "token_not_valid") {
                          console.log("token not valid");
                          //router.push("/");
                          setRedirectAndLogout(true);
                        }
                        return response;
                      }
                    }}
                  >
                    <StateProvider>
                      <Head>
                        <title>Meta Merch</title>
                        <meta name="description" content="Meta Merch" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        />
                        <link rel="icon" href="/favicon.ico" />
                      </Head>
                      <AnimatePresence
                        mode="wait"
                        initial={false}
                        onExitComplete={() => window.scrollTo(0, 0)}
                      >
                        {loading && <PageLoader />}
                        <Component {...pageProps} />
                      </AnimatePresence>
                    </StateProvider>
                  </SWRConfig>
                )
              );
            }}
          </SessionContext.Consumer>
        </SessionProvider>
      </IntercomProvider>
      </PlausibleProvider>
  );
}
